<template>
  <div>
    <b-row align-v="center"
           class="text-center">
      <b-col cols="auto">
        OPEN CALL :
      </b-col>
      <b-col class="text-left">
        <div style="width: 195px;">
          <b-form-select v-if="!loading"
                         v-model="selected_call"
                         :options="calls"/>
          <b-spinner v-else
                     class="ml-2 mt-1"
                     variant="info"/>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  export default {
    props: {
      load_data_for: undefined,
      value: undefined,
      id: undefined,
      allCalls: {
        type: Boolean,
        default: false,
      },
      loading: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        proposals: this.value,
        selected_call: '',
        proposals_cached: {},
      };
    },
    methods: {
      update_proposals() {
        if (!this.is_call_cached()) {
          // emit signal to load proposal in parent for specific call
          this.$emit('load_data', this.selected_call);
        } else {
          // emit proposal from cache to update in the parent widget
          this.$emit('update_proposals', this.proposals_cached[this.selected_call]);
        }
      },
      is_call_cached() {
        return Object.keys(this.proposals_cached).includes(this.selected_call);
      },
      setup(proposal) {
        this.proposals_cached[this.selected_call] = proposal;
      },
      refresh() {
        this.$emit('load_data', this.selected_call);
      },
    },
    watch: {
      selected_call() {
        this.update_proposals();
        this.$emit('selected_call_change', this.selected_call);
      },
      proposals() {
        this.$emit('input', this.proposals);
      },
      value() {
        this.proposals = this.value;
      },
    },
    computed: {
      calls() {
        const callsNames = this.$store.state.calls.calls.map((call) => call.name);
        if (this.allCalls) {
          callsNames.push('All calls');
        }
        return callsNames;
      },
    },
    created() {
      setTimeout(() => {
        const parent = this.get_parent_by_id(this.id);
        parent.$on('setup_proposal', this.setup);
        parent.$on('refresh_proposals', this.refresh);

        // load and setup the default selected call (the newest)
        this.$store.dispatch('calls/load_calls')
          .then(() => this.selected_call = this.calls[0]);
      }, 0);
    },
  };
</script>

<style scoped>

</style>

import { getHelpersProperties } from '@/helpers';

export const envMixin = {
  computed: {
    environment() {
      const arr = this.$route.path.split('/');
      return arr[arr.length - 1];
    },
  },
  methods: {
    async getFinalGradeProperties(environment) {
      return getHelpersProperties(`${environment}FinalGrade`);
    },
    async getManagementEvaluationProperties(environment) {
      return getHelpersProperties(`${environment}ManagementEvaluation`);
    },
    async getSafetyEvaluationProperties(environment) {
      return getHelpersProperties(`${environment}SafetyEvaluation`);
    },
    async getRadiationEvaluationProperties(environment) {
      return getHelpersProperties(`${environment}RadiationEvaluation`);
    },
  },
};

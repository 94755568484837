<template>
  <b-button v-bind="attrsButton"
            v-on="$listeners"
            :disabled="loading">
    <template v-if="loading">
      <b-spinner v-bind="$attrs.spinner"
                 small/>
      <slot name="spinnerText">
        DOWNLOADING...
      </slot>
    </template>
    <template v-else>
      <slot name="defaultText">
        PROPOSALS TO ZIP
      </slot>
    </template>
  </b-button>
</template>

<script>
  export default {
    name: 'buttonSpinner',
    props: {
      loading: {
        type: Boolean,
        required: true,
      },
    },
    computed: {
      attrsButton() {
        const { spinner, ...button } = this.$attrs;
        return button;
      },
    },
    inheritAttrs: false,
  };
</script>

<style scoped>

</style>

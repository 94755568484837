<template>
  <div>
    <b-row>
      <b-col>
        <label class="mt-2 font-weight-bold"
               for="grade">Grade<span
          class="grey--text">  0 (bad) - 10 (great)</span></label>
        <b-form-input id="grade"
                      v-model="proposalFromValue.proposal.review.grade"
                      :readonly="disabled"
                      max="10"
                      min="0"
                      step="0.5"
                      type="number"
                      @paste.prevent/>
      </b-col>
      <b-col>
        <label class="mt-2 font-weight-bold">Suggested number of shifts</label>
        <b-input-group v-for="shift in shifts_config"
                       :key="shift.model"
                       :append="shift.type"
                       :class="shift.class">
          <b-form-input v-model="proposalFromValue.proposal.review[shift.model]"
                        :max="shift.max"
                        :min="shift.min"
                        :step="shift.step"
                        :readonly="shift.readonly || disabled"
                        placeholder="Number of shifts"
                        type="number"
                        @paste.prevent/>
        </b-input-group>
      </b-col>
    </b-row>

    <b-form-group label="Confidential comment for the Panel (visible for Reviewers and Committee only)"
                  label-class="mt-2 font-weight-bold"
                  label-for="comment">
      <b-form-textarea id="comment"
                       v-model="proposalFromValue.proposal.review.comments"
                       :readonly="disabled"
                       rows="3"/>
    </b-form-group>

    <b-button class="ma-1 right"
              variant="info"
              @click="save_review">
      SAVE
    </b-button>

  </div>
</template>

<script>
  import { envMixin } from '@/mixins/environment';

  export default {
    name: 'review_form',
    props: {
      value: {
        type: Object,
        default: () => {
        },
      },
      shifts_config: {
        type: Array,
        default: () => [],
      },
    },
    computed: {
      disabled() {
        return this.proposalFromValue.proposal.review.states.name !== 'draft';
      },
    },
    mixins: [envMixin],
    data() {
      return { proposalFromValue: this.value };
    },
    methods: {
      save_review() {
        const url = `/documents/${this.proposalFromValue.proposal._id.$oid}/review/${this.environment}`;
        this.axios.post(url, this.proposalFromValue.proposal.review)
          .then(() => {
            this.$notify({ type: 'success', title: 'Saved successfully!' });
            // emit hideModal signal must be handle by parent component
            this.close_modal_emit();
          })
          .catch(() => {
            this.$notify({ type: 'error', title: 'Could not save!' });
          });
      },
    },
  };
</script>

<style scoped>

</style>

<template>

  <b-card bg-variant="light"
          header="Reviews"
          header-bg-variant="dark"
          header-text-variant="white">

    <b-row align-h="between">

      <b-col cols="auto">
        <b-row id="filters">

          <b-col cols="auto">
            <b-button :pressed.sync="showFilter"
                      class="mb-2 mr-2">
              FILTER
            </b-button>
          </b-col>

          <b-col cols="auto">
            <keep-alive>
              <call-filter v-if="isNotReportsTab"
                           :id="id"
                           ref="call_filter"
                           v-model="reviews"
                           :loading="loading"
                           all-calls
                           class="mb-2"
                           @load_data="loadDataFor"
                           @selected_call_change="call_change"
                           @update_proposals="refresh_proposals_filter"/>
            </keep-alive>
          </b-col>

        </b-row>
      </b-col>

      <b-col cols="auto">
        <b-row>

          <b-col v-if="isProposalsToZipEnabled"
                 class="mb-2">
            <button-spinner :loading="zipLoading"
                            :spinner="{color: 'warning'}"
                            variant="info"
                            @click="downloadZipWrapper">
              <template v-slot:defaultText>
                <span>PROPOSALS TO ZIP ({{ buttonZipTextAppend }})</span>
              </template>
            </button-spinner>
          </b-col>

        </b-row>
      </b-col>
    </b-row>

    <b-tabs v-model="selectedTab"
            card>
      <b-tab active
             title="Reviews">
        <div v-if="isCryoemDashboard">
          <p class="ml-3 p-form-description">1-day shift for proposals submitted after Spring 2024 - 13th call</p>
          <p class="ml-3 p-form-description">2.5-day shift for proposals submitted until Spring 2024 – 13th call</p>
        </div>
          <b-table :current-page="currentPage"
                 :fields="fields"
                 :items="reviews"
                 :per-page="perPage"
                 :responsive="true"
                 class="table-responsive"
                 head-variant="light"
                 hover
                 striped
                 thead-class="text-center">

          <template v-slot:top-row="{fields}">
            <proposal-filter :id="id"
                             v-model="reviews"
                             :fields="fields"
                             :not_include_key="['actions', 'ordinalNumber']"
                             :selected_call="selected_call"/>
          </template>

          <template v-slot:cell(ordinalNumber)="row">
            <base-column-ordinal-number v-bind="{index: row.index, perPage: perPage, currentPage: currentPage}"/>
          </template>

          <template v-slot:cell(actions)="row">
            <action-download-pdf :proposal="row.item.proposal"/>

            <base-icon-button-comment v-if="row.item.editable"
                                      v-b-modal.review_modal
                                      title="Edit review"
                                      @click="setReview(row.item)"/>

            <action-open-proposal-full-details :proposal="row.item.proposal"/>

            <base-icon-button-send v-if="row.item.editable"
                                   class="d-block ml-auto"
                                   title="Submit review"
                                   @click="openConfirmationModal(row.item)"/>
          </template>
        </b-table>
        <b-table-footer v-model="loading"
                        :currentPage="currentPage"
                        :data_length="reviews.length"
                        :perPage="perPage"
                        @change_page="changePage"/>
        <b-modal id="review_modal"
                 :ref="modal_ref"
                 bg-variant="light"
                 header-bg-variant="dark"
                 header-text-variant="white"
                 hide-footer
                 lazy
                 size="lg"
                 title="Review form"
                 @hidden="clean_data">
          <review-modal v-model="review"
                        :fields="modalFields"
                        :shifts_config="shiftsConfig"
                        @closeModal="close_modal_slot"/>
        </b-modal>

        <review-confirmation-modal ref="submitConfirmationModal"
                                   :proposal-id="selectedProposalId"
                                   @refresh-proposals="emitRefreshProposals"/>

      </b-tab>
      <b-tab lazy
             title="Reports">
        <experiment-list :elevated="false"
                         :load_data_url="experimentsUrl"
                         :prop_fields="reportFields"
                         :show_zip_button="true"/>
      </b-tab>
    </b-tabs>
  </b-card>

</template>

<script>
  import ActionDownloadPdf from '@/components/actions/ActionDownloadPdf';
  import ActionOpenProposalFullDetails from '@/components/actions/ActionOpenProposalFullDetails';
  import { envMixin } from '@/mixins/environment';
  import BaseIconButtonComment from '@/components/baseComponents/baseIcons/BaseIconButtonComment';
  import BaseIconButtonSend from '@/components/baseComponents/baseIcons/BaseIconButtonSend';
  import ButtonSpinner from '@/components/buttonSpinner';
  import bTableFooter from '@/components/b_table_footer';
  import CallFilter from '@/components/call_filter';
  import ExperimentList from '@/components/proposal/experiment_report/experiment_list';
  import ProposalFilter from '@/components/proposal_filter';
  import BaseColumnOrdinalNumber from '@/components/baseComponents/BaseColumnOrdinalNumber';
  import { mapGetters } from 'vuex';
  import ReviewConfirmationModal from '@/components/evaluation/review/ReviewConfirmationModal';
  import ReviewModal from './review_modal';

  export default {
    name: 'ReviewDashboard',
    props: {
      isCryoemDashboard: {
        type: Boolean,
        required: false,
      },
      fields: {
        type: Array,
        default: () => [],
      },
      modalFields: {
        type: Array,
        default: () => [],
      },
      shiftsConfig: {
        type: Array,
        default: () => [],
      },
      reportFields: {
        type: Array,
        default: () => [],
      },
      experimentsUrl: {
        type: String,
        default: '',
      },
    },
    components: {
      ActionOpenProposalFullDetails,
      ActionDownloadPdf,
      BaseIconButtonComment,
      BaseIconButtonSend,
      BaseColumnOrdinalNumber,
      ButtonSpinner,
      ReviewModal,
      ExperimentList,
      CallFilter,
      ProposalFilter,
      bTableFooter,
      ReviewConfirmationModal,
    },
    mixins: [envMixin],
    data() {
      return {
        selectedTab: 0,
        // necessary for proposal and calls filter feature
        id: 1,
        selected_call: '',
        // end
        // necessary for b-table feature
        currentPage: 1,
        perPage: 20,
        // end
        // use to handle zip filtered proposals
        filteredProposals: {},
        allProposalsLength: 0,
        buttonZipTextAppend: 'ALL',
        // use to hide modal
        modal_ref: 'modal_ref',

        // use only for visual effect and trigger event for filter component
        showFilter: false,
        loading: false,
        zipLoading: false,
        reviews: [],
        review: {},
      };
    },
    computed: {
      ...mapGetters('login', ['loggedUserId']),
      isProposalsToZipEnabled() {
        return this.selectedTab === 0;
      },
      isNotReportsTab() {
        return this.selectedTab !== 1;
      },
      selectedProposalId() {
        if (this.review.proposal) {
          return this.review.proposal._id.$oid;
        }
        return '';
      },
    },
    methods: {
      loadDataFor(call) {
        this.loading = true;
        const callParam = { call };
        this.axios.get(`/documents/reviews/${this.environment}`, { params: callParam })
          .then((resp) => {
            this.retrieveReviewsFromProposals(resp.data.documents);
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      },
      setReview(review) {
        this.review = review;
      },
      retrieveReviewsFromProposals(proposals) {
        this.reviews = [];
        proposals.filter((proposal) => proposal.reviews).forEach((proposal) => {
          proposal.reviews.forEach((review) => {
            const isSameUser = review.user_id === this.loggedUserId;

            // documentSpecificationWithType should be removed when backend endpoint will start to deliver that key
            const documentSpecificationWithType = { document_specification: proposal.document_specification };
            documentSpecificationWithType.document_specification.type = this.environment;

            this.reviews.push({
              proposal: { ...proposal, review, ...documentSpecificationWithType },
              editable: review.states.name === 'draft' && isSameUser,
              priority: Number(isSameUser),
            });
          });
        });
        this.reviews.sort((itemOne, itemTwo) => itemTwo.priority - itemOne.priority);

        // update data in proposal-filter, call-filter component
        this.$emit('setup_proposal', this.reviews);
        this.allProposalsLength = this.reviews.length;
      },
      changePage(val) {
        this.currentPage = val;
      },
      updateButtonZipTextAppend() {
        const filteredProposalsLength = this.reviews.length;
        this.buttonZipTextAppend = this.allProposalsLength === filteredProposalsLength ? 'ALL'
          : filteredProposalsLength.toString();
      },
      filteredProposalIDRange(documents) {
        const uniqueProposalIds = new Set();
        documents.forEach((obj) => {
          uniqueProposalIds.add(obj.proposal.document_specification.document_id);
        });
        return Array.from(uniqueProposalIds);
      },
      async downloadZipWrapper() {
        this.zipLoading = true;
        // eslint-disable-next-line camelcase
        const proposalIDRange = { proposal_id_range: this.filteredProposalIDRange(this.reviews) };
        const callParam = { call: this.$refs.call_filter?.selected_call ?? null };
        await this.download_zip(callParam, proposalIDRange);
        this.zipLoading = false;
      },
      openConfirmationModal(review) {
        this.setReview(review);
        this.$refs.submitConfirmationModal.openModal();
      },
      emitRefreshProposals() {
        this.$emit('refresh_proposals');
      },
    },
    watch: {
      showFilter() {
        this.$emit('toggle_show');
      },
      reviews() {
        this.updateButtonZipTextAppend();
      },
    },
  };
</script>

<style scoped>
  .card {
    margin: 2rem;
  }

  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    text-align: center !important;
  }

  ::-moz-placeholder { /* Firefox 19+ */
    text-align: center !important;
  }

  :-ms-input-placeholder { /* IE 10+ */
    text-align: center !important;
  }

  :-moz-placeholder { /* Firefox 18- */
    text-align: center !important;
  }
</style>
